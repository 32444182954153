import firebase from '@/services/firebase';
import {axiosData} from '../api/axios';
import Cookies from 'js-cookie';

const state = {
    user: JSON.parse(localStorage.getItem('user')) || null,
    failedAttempts: JSON.parse(localStorage.getItem('failedAttempts')) || 0,
    lockTime: JSON.parse(localStorage.getItem('lockTime')) || null,
}

const getters = {
    getUser(state) {
        return state.user ? state.user : null;
    },
    getFailedAttempts(state) {
        return state.failedAttempts ? state.failedAttempts : 0;
    },
    getLockTime(state) {
        return state.lockTime ? state.lockTime : null;
    },
}

const actions = {
    setUser({ commit }, payload) {
        commit('SET_USER', payload);
        payload
            ? localStorage.setItem('user', JSON.stringify(payload))
            : localStorage.removeItem('user');
    },
    async getSpainTime() {

        // Obtener la fecha y hora actual en UTC
        const now = new Date();

// España es UTC+1, o UTC+2 en horario de verano
        const spainOffset = now.getTimezoneOffset() / 60 === -2 ? 2 : 1; // Detectar si es horario de verano

// Convertir a hora de España
        const spainTime = new Date(now.getTime() + (spainOffset * 60 * 60 * 1000));

// Formatear la fecha en YYYY-MM-DD
        const year = spainTime.getFullYear();
        const month = ('0' + (spainTime.getMonth() + 1)).slice(-2);
        const day = ('0' + spainTime.getDate()).slice(-2);

// Formatear la hora en hh:mm:ss
        const hours = ('0' + spainTime.getHours()).slice(-2);
        const minutes = ('0' + spainTime.getMinutes()).slice(-2);
        const seconds = ('0' + spainTime.getSeconds()).slice(-2);

// Combinar la fecha y la hora en el formato deseado
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    },
    async loginGoogleFirebase(_,provider) {
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Logeando Usuario por Google');

        try {
            firebase.auth().languageCode = 'es'

            const data = await firebase
                .auth()
                .signInWithPopup(provider);

            if (data) {
                const user = data.user;
                const horaTiempo  = await this.dispatch('session/getSpainTime');

                const payLogin ={
                    id: null,
                    fbid: user.uid,
                    email : user.email,
                    nombre: user.displayName,
                    foto :  user.photoURL,
                    createdAt: horaTiempo
                };
                if (payLogin.nombre === ""){
                    payLogin.nombre = "Nombre";
                }
                return await this.dispatch('session/workData', {
                    data: data,
                    persona: payLogin
                });
            }
        } catch (error) {
            await this.dispatch('session/muestraErrores',error)
        }
    },
    async workData(_,work){

        const tokenData = await work.data.user.getIdTokenResult();

        if (tokenData) {

            this.dispatch('session/setUserToken', {
                tokenId: tokenData.token,
                expirationTime: tokenData.expirationTime,
                exp: tokenData.claims.exp,
            });
        }
        const loginData = await this.dispatch('session/compruebaEmail',work.persona);

        if (loginData) {
            return loginData;
        } else {
            this.dispatch('session/logout');
            return false;
        }
    },
    async editar(_,user){
        try {
            this.dispatch('ui/handleLoaderBtn', true);

            const data = await axiosData(
                true,
                'usuario/save',
                user,
                'post',
                false,
                false
            );
            if (data) this.dispatch('ui/handleLoaderBtn', false);

            if (data.status) return false;

            if (typeof data === 'number') return false;

            return user;



        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async compruebaEmail(_,payload){
        try {
            this.dispatch('ui/handleLoaderBtn', true);

            const data = await axiosData(
                true,
                'usuario/email/'+payload.email,
                'get',
                false,
                false
            );
            if (data) this.dispatch('ui/handleLoaderBtn', false);

            if (data.status) return false;

            if (typeof data === 'number') return false;
            if(data.id === null){
                return this.dispatch('session/registrar',payload)
            }else{
                if(data.fbid == null) {
                    data.fbid = payload.fbid
                    return this.dispatch('session/editar',data)
                }else{
                    if(payload.fbid !== data.fbid ){
                        data.fbid = payload.fbid
                        return this.dispatch('session/editar',data)
                    }else{

                        return payload;
                    }
                }
            }
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
        }
    },
    async muestraErrores(_,error){
        this.dispatch('ui/handleLoaderBtn', false);

        const errorMessages = {
            'session/invalid-email': 'El formato del email es incorrecto.',
            'session/user-not-found': 'No existe un usuario asociado a este email.',
            'session/wrong-password': 'La contraseña es incorrecta.',
            'session/email-already-exists': 'Su Correo existe en nuestra base de datos de eventos con Google Mail o Apple Login por favor intente con Login con Google o Login con Apple, tambien puede que se le haya enviado un correo para que cambie el password.',
        };

        const errorMessage = errorMessages[error.code] || error.message;
        this.dispatch('ui/setError', {
            code: error.code,
            message: errorMessage,
        });
    },
    async login(_, user) {
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Logeeando Usuario');

        try {
            const payload = {
                "uuid" : user.uuid,
                "token": user.token
            }
            const data = await axiosData(
                true,
                'usuario/loginbyuid',
                payload ,
                'post',
                false,
                false
            );

            if (data.status) return false;

            if (typeof data === 'number') return false;

            if (data.usuario.id !== null) {
                this.dispatch('session/setUser', data.usuario);
            }


            if (data){
                this.dispatch('ui/handleLoaderBtn', false);
            }

            return true;
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Bingo, error: ${error}`);
        }
    },
    async logout() {
        this.dispatch('ui/handleLoader', true);
        try {

            await firebase.auth().signOut();
            this.dispatch('ui/handleLoaderBtn', false);
            this.dispatch('session/setUser', null);
            this.dispatch('partida/setPartida', null);
            this.dispatch('cartilla/setCartilla', null);
            this.dispatch('session/setUserToken', null);
            this.dispatch('ui/handleLoaderBtn', false);


        } catch (error) {
            this.dispatch('ui/handleLoader', false);
            throw new Error(`Error Comunicacion con Bingo, error: ${error}`);
        }
    },
    async registrar(_,payLogin){
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Registrando Persona');

        try {
            const data = await axiosData(
                true,
                'usuario/registrar',
                payLogin,
                'post',
                false,
                false
            );

            if (data) this.dispatch('ui/handleLoaderBtn', false);

            if (typeof data === 'number') return false;
            if (data.usuario.id !== null) {
                this.dispatch('session/setUser', data.usuario);
            }
            if (payLogin) {
                return payLogin;
            }
        } catch (error) {
            this.dispatch('ui/handleLoaderBtn', false);
            throw new Error(`Error Comunicacion con Bingo, error: ${error}`);
        }
    },
    async loginFirebase({ getters, commit }, payload) {
        const failedAttempts = getters.getFailedAttempts;
        const lockTime = getters.getLockTime;

        if (lockTime && Date.now() < lockTime) {
            const timeLeft = Math.round((lockTime - Date.now()) / 60000); // Time left in minutes
            await this.dispatch('session/muestraErrores', { message: `Login esta bloqueado para este usuario. Trate de nuevo en  ${timeLeft} .` });
            return;
        }

        this.dispatch('ui/handleLoaderBtn', true);

        try {

            const data = await firebase
                .auth()
                .signInWithEmailAndPassword(payload.email, payload.password);

            if(data){
                const user = data.user;
                const horaTiempo  = await this.dispatch('session/getSpainTime');
                const payLogin ={
                    id: null,
                    fbid: user.uid,
                    email : user.email,
                    nombre: user.displayName,
                    foto :  user.photoURL,
                    createdAt: horaTiempo
                };
                const compr =  await this.dispatch('session/workData',{
                    data: data,
                    persona: payLogin
                });
                commit('resetFailedAttempts');
                return compr;
            }
        } catch (error) {
            // Increment failed attempts in the state
            commit('incrementFailedAttempts');

            // Lock the login if failed attempts exceed 5
            if (failedAttempts >= 3) {
                const lockDuration = 15 * 60 * 1000; // 15 minutes
                const newLockTime = Date.now() + lockDuration;
                commit('setLockTime', newLockTime); // Update lockTime in the state
                await this.dispatch('session/muestraErrores', { message: "Muchos intentos fallados. Login esta bloqueado por 15 minutos." });
            } else {
                // Handle specific Firebase errors
                if (error.code === "auth/email-already-in-use") {
                    await firebase.auth().sendPasswordResetEmail(payload.email);
                }
                await this.dispatch('session/muestraErrores', error);
            }
        } finally {
            this.dispatch('ui/handleLoaderBtn', false);
        }
    },
    async createFirebase(_,  payload ) {
        this.dispatch('ui/handleLoaderBtn', true);
        this.dispatch('ui/setTextLoading', 'Registrando');

        try {
            const data = await firebase
                .auth()
                .createUserWithEmailAndPassword(payload.data.email, payload.password);

            if (data) {

                const user = data.user;
                const horaTiempo  = await this.dispatch('session/getSpainTime');

                const payLogin ={
                    id: null,
                    fbid: user.uid,
                    email : user.email,
                    nombre: payload.data.nombre,
                    foto :  user.photoURL,
                    createdAt: horaTiempo
                };

                const compr =  await this.dispatch('session/workData',{
                    data: data,
                    persona: payLogin
                });

                return compr;
            }
        } catch (error) {
            if(error.code === "auth/email-already-in-use"){
                await firebase
                    .auth()
                    .sendPasswordResetEmail(payload.data.email);
            }
            await this.dispatch('session/muestraErrores',error)
        }
    },
    setUserToken(_, payload) {

        if (payload) {
            const  expirationTime  = payload.expirationTime;
            Cookies.set('token', JSON.stringify(payload), {
                expires: new Date(expirationTime),
            });
        } else {
            Cookies.remove('token');
        }
    },
}

const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
        localStorage.setItem('user', JSON.stringify(payload));
    },

    incrementFailedAttempts(state) {
        state.failedAttempts++;
        localStorage.setItem('failedAttempts', JSON.stringify(state.failedAttempts)); // Keep in sync with localStorage
    },
    resetFailedAttempts(state) {
        state.failedAttempts = 0;
        localStorage.setItem('failedAttempts', JSON.stringify(state.failedAttempts)); // Reset in localStorage
    },
    setLockTime(state, lockTime) {
        state.lockTime = lockTime;
        localStorage.setItem('lockTime', JSON.stringify(lockTime)); // Update lockTime in localStorage
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
