import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)


const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: () => import("@/components/HomePage.vue"),
        },
        {
            path: '/:tag',
            name: 'CartillaPersonalizada',
            component: () => import("@/components/general/cartilla.vue"),
            meta: {
                layout: 'WithoutLayout'
            }
        },
        {
            path: '/adm/bingo/crear',
            name: 'CrearBingo',
            component: () => import("@/components/administrar/CrearBingo.vue"),
            meta: {
                layout: 'PanelLayout'
            }
        },
        {
            path: '/adm/bingo/ver',
            name: 'VerBingo',
            component: () => import("@/components/administrar/VerBingo.vue"),
            meta: {
                layout: 'PanelLayout'
            }
        },
        {
            path: '/adm/tarjeta/ver',
            name: 'VerTarjetas',
            component: () => import("@/components/administrar/VerTarjetas.vue"),
            meta: {
                layout: 'PanelLayout'
            }
        },
        {
            path: '/adm/tarjeta/crear',
            name: 'CrearTarjeta',
            component: () => import("@/components/administrar/CrearTarjeta.vue"),
            meta: {
                layout: 'PanelLayout'
            }
        },
        {
            path: '/adm/personaliza/web',
            name: 'PersonalizaWeb',
            component: () => import("@/components/administrar/PersonalizaWeb.vue"),
            meta: {
                layout: 'PanelLayout'
            }
        },

    ]
});
router.beforeEach((to, from, next) => {
    // Cambiar el título dinámicamente
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Bingo'; // Título por defecto
    }

    // Cambiar el favicon dinámicamente
    if (to.meta.favicon) {
        const link = document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = to.meta.favicon;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = to.meta.favicon;
            document.head.appendChild(newLink);
        }
    }

    next();
});
export default router