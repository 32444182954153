<template>
  <div id="app" style="background-color: #f8e7c7 !important; ">
    <component :is="layout">
      <transition :name="transitionName" mode="out-in">
        <AlertMessage v-if="alertamen" :color="colores" :message="mensajess"/>
        <router-view></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import { localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';
import i18n from "@/i18n";
import firebase from '@/services/firebase';
import Cookies from 'js-cookie';
import AlertMessage from "./components/componente/AlertMessage.vue";


localize({es});

export default {
  name: 'App',
  components: {
    AlertMessage,
    DefaultLayout: () => import('@/layouts/Default.vue'),
    ErrorLayout: () => import('@/layouts/Error.vue'),
    PanelLayout: () => import('@/layouts/Panel.vue'),
    WithoutLayout: () => import('@/layouts/Without.vue'),

  },
  data: () => ({
    transitionName: 'fade',
    intervalOnActive: null,
    alertamen: false,
    colores: '',
    mensajess: '',
    intervalOnIdle: null,
    timeout: null,
  }),
  metaInfo() {
    return {
      titleTemplate: `%s`,
      htmlAttrs: {
        lang: i18n.t("config.lang")
      }
    }
  },
  created() {
    this.$router.afterEach((to, from) => {
      if (to.meta.layout !== from.meta.layout && this.getUser) {
        this.clearNotifications()
      }
    })
    this.$vuetify.theme.dark = false
  },
  watch: {
    $route(to, from) {
      // Determina la transición en función de la ruta actual y la ruta anterior
      if (to.name === 'ComponenteB' && from.name === 'ComponenteA') {
        this.transitionName = 'fade-componente-b-desde-a';
      } else {
        this.transitionName = 'fade'; // Transición predeterminada
      }
    }
  },
  mounted: function () {
    this.checkAppVersion();

    this.resolveResolution();
    this.checkLastActive();

  },
  methods: {
    ...mapActions([
      'updateResolution',
    ]),
    ...mapActions("ui", [
      "setError",
      "clearNotifications",
    ]),
    ...mapActions("session", ["logout", "setUserToken"]),
    checkAppVersion() {
      const storedVersion = localStorage.getItem('appVersion');
      const APP_VERSION = process.env.VUE_APP_VERSION;

      if (storedVersion === null || storedVersion === undefined || storedVersion === "undefined") {
        localStorage.setItem('appVersion', APP_VERSION);
      } else if (storedVersion !== APP_VERSION) {
        // Si la versión ha cambiado, fuerza una recarga
        localStorage.setItem('appVersion', APP_VERSION);
        // Opción 1: Forzar la recarga del navegador para cargar la nueva versión
        window.location.reload(true);

        // Opción 2: Mostrar un mensaje al usuario pidiendo que recargue la página
        // this.$notify({ type: 'info', message: 'Nueva versión disponible. Por favor, recargue la página.' });
      }
    },
    logoutAndClearSession() {
      this.logout(); // Tu función de logout
      this.setError({
        message: 'Tu sesión ha expirado por inactividad'
      });
      this.alertamen = true;
      this.colores = 'error';
      this.mensajess = 'Tu sesión ha expirado por inactividad';
      localStorage.clear(); // Limpia el localStorage
      Cookies.remove('token'); // Borra la cookie del token
      window.location.href = '/';
    },
    checkLastActive() {
      const token = Cookies.get('token') ? JSON.parse(Cookies.get('token')) : null;
      if (token !== null) {
        if (token && this.getUser) {
          const lastActive = localStorage.getItem('lastActive')
              ? parseInt(localStorage.getItem('lastActive'), 10)
              : null;
          const currentUnixTime = Math.floor(new Date().getTime() / 1000);

          if (lastActive && token.exp < currentUnixTime) {
            this.logoutAndClearSession();
          }
        }
      } else {
        this.logout();
      }
    },
    resolveResolution() {
      const size = window.innerWidth;
      this.isMobile = size <= 768;
      this.updateResolution(this.isMobile);
    },

  },
  onActive() {
    clearTimeout(this.intervalOnIdle)

    const token = Cookies.get('token')
        ? JSON.parse(Cookies.get('token'))
        : null

    if (token && this.getUser) {
      clearInterval(this.intervalOnActive)

      this.intervalOnActive = setInterval(() => {
        const checkToken = Cookies.get('token')
            ? JSON.parse(Cookies.get('token'))
            : null

        const currentUnixTime = Math.floor(new Date().getTime() / 1000);

        localStorage.setItem('lastActive', String(currentUnixTime))
        if (checkToken) {
          const isTokenCloseToExpired = checkToken.exp - currentUnixTime < 60 * 50;
          if (isTokenCloseToExpired) {
            firebase.auth().onAuthStateChanged(async (user) => {
              if (user) {
                try {
                  await user.getIdToken(true);
                  const newTokenData = await user.getIdTokenResult();
                  if (newTokenData) {
                    this.setUserToken({
                      tokenId: newTokenData.token,
                      expirationTime: newTokenData.expirationTime,
                      exp: newTokenData.claims.exp
                    })
                  }
                } catch (error) {
                  this.logoutAndClearSession();
                }
              } else {
                this.logoutAndClearSession();
              }
            })
          }

        }

      }, 1000)
    }
  },
  onIdle() {
    clearInterval(this.intervalOnActive)

    const token = Cookies.get('token')
        ? JSON.parse(Cookies.get('token'))
        : null
    if (token !== null) {
      if (token && this.getUser) {
        clearInterval(this.intervalOnIdle)

        this.intervalOnIdle = setInterval(() => {
          const currentUnixTime = localStorage.getItem('lastActive')
              ? localStorage.getItem('lastActive')
              : new Date().getTime() / 1000

          const tokenIsExpired = token.exp < currentUnixTime

          if (tokenIsExpired) {
            this.logoutAndClearSession();
          }
        }, 1000)
      }
    } else {
      this.logout();
    }
  },

  computed: {
    ...mapState('ui', ['error', 'success']),
    ...mapGetters('session', ['getUser']),
    layout() {
      return this.$route.meta.layout || 'DefaultLayout';
    },
  }
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-componente-a-desde-b-enter-active,
.fade-componente-b-desde-a-enter-active,
.fade-componente-a-desde-b-leave-active,
.fade-componente-b-desde-a-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.fade-componente-a-desde-b-enter, .fade-componente-b-desde-a-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.fade-componente-a-desde-b-leave-active,
.fade-componente-b-desde-a-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.loading {
  position: fixed;
  z-index: 100000;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.cursor-pointer {
  cursor: pointer;
}
@font-face {
  font-family: 'RegulatorsExpanded';
  src: url('./assets/fonts/RegulatorsExpanded.eot');
  src: local('Brutal Type Extra Light'), local('RegulatorsExpanded'),
  url('./assets/fonts/RegulatorsExpanded.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RegulatorsExpanded.woff2') format('woff2'),
  url('./assets/fonts/RegulatorsExpanded.woff') format('woff'),
  url('./assets/fonts/RegulatorsExpanded.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}


</style>
