import {axiosData} from "@/store/api/axios";

const state = () => ({
  partidas: JSON.parse(localStorage.getItem('partidas')) || null,
  partida: JSON.parse(localStorage.getItem('partida')) || null,

});

const getters = {
  getPartidas(state) {
    return state.partidas;
  },
  getPartida(state) {
    return state.partida;
  },
};

const actions = {
  setPartida({ commit }, payload) {
    commit('SET_PARTIDA', payload);
    payload
        ? localStorage.setItem('partida', JSON.stringify(payload))
        : localStorage.removeItem('partida');
  },
  setPartidas({ commit }, payload) {
    commit('SET_PARTIDAS', payload);
    payload
        ? localStorage.setItem('partidas', JSON.stringify(payload))
        : localStorage.removeItem('partidas');
  },
  async damePartidasXUsuario(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Bingos');
    try {
      const data = await axiosData(
          true,
          'partidas/usuario/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async damePartidas(){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Bingos');
    try {
      const data = await axiosData(
          true,
          'partidas',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async borrarPartida(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'partidas/'+id,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async damePartida(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'partidas/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},

  async existePorTag(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Verificando Tag');
    try {
      const data = await axiosData(
          true,
          'partidas/tag/'+id,
          'get',
          false,
          false
      );

      if ([404, 400, null, undefined].includes(data)) {
        return null;
      }

      return data;

    } catch (error) {
      console.error("Error en existePorTag:", error);
      return [];
    } finally {
      this.dispatch('ui/handleLoaderBtn', false); // Desactivar loader
    }
  },

  async grabarPartida(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'partidas',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('partida/setPartida',data)

      }else{
        this.dispatch('ui/handleLoaderBtn', false);

      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
  async updatePartida(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Trae Jugador');
    try {
      const data = await axiosData(
          true,
          'partidas/'+payload.id,
          payload.payload,
          'put',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Bingo api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_PARTIDAS(state, payload) {
    state.partidas = payload;
    localStorage.setItem('partidas', JSON.stringify(payload));
  },
  SET_PARTIDA(state, payload) {
    state.partida = payload;
    localStorage.setItem('partida', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
