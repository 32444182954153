import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#F8E7C7',
                secondary: '#6CCBF4',
                accent: '#A1DDFF',
                error: '#b71c1c',
            },
        },
    },
    icons: {
        iconfont: 'fa',
      },
});
